<template lang='pug'>
  v-container#user-profile.custom-container.user-profile(fluid="" tag="section" v-if="profile")
    // Dialog update profile
    v-dialog( v-model="updateItemDialog" persistent   max-iwdth='800px'   transition="dialog-bottom-transition")
      div(style ='position:relative; height: 100%;')
        update-item(:model ='profile' @close="updateDialog(false)")
        div(style='position:absolute; top:0; width:100%;')
          v-btn.mt-2.ml-2(absolute right  icon color='danger' style="top: -45px; z-index:999;" medium,  dark='', @click.native='updateDialog(false)')
            v-icon(size="30") mdi-close-box
    //- Avatar
    v-row
      v-col
        Avatar(:profile="profile" userType="bussiness" v-if="isMobile" @openUpdate="updateDialog(true)" )
    //- information
    v-row(justify="center")
        v-col(cols="12" md="12")
          v-card.custom-card-information.mt-0(v-if="isMobile")
            v-tabs(left class="pt-5 pb-5 pr-4 pl-4")
              // Tabs
              v-tab
                v-icon(left="") mdi-account
                | Profile
              v-tab
                v-icon(left="") mdi-book
                | History
              v-tab
                v-icon(left="") mdi-currency-usd
                | Plan
              v-tab
                v-icon(left="") mdi-currency-usd
                | Payments
              // Profile tab
              v-tab-item
                v-form
                  v-container.py-0
                    v-row(no-gutters)
                      v-col(cols="4")
                        v-subheader.pa-0.mt-3 First Name
                      v-col(cols="8")
                        v-text-field.purple-input(disabled="" v-model="profile.first_name")
                      v-col(cols="4")
                        v-subheader.pa-0.mt-3 Last Name
                      v-col(cols="8")
                        v-text-field.purple-input(disabled="" v-model="profile.last_name")
                      v-col(cols="4")
                        v-subheader.pa-0.mt-3 Email
                      v-col(cols="8")
                        v-text-field.purple-input(disabled="" v-model="profile.email")
                      v-col(cols="4")
                        v-subheader.pa-0.mt-3 Phone
                      v-col(cols="8")
                        v-text-field.purple-input(disabled="" v-model="profile.phone")
              // History tab
              v-tab-item
                v-card
                  v-container.py-0
                    v-form
                      v-row
                        v-col(cols="12" md="6")
                          v-text-field(label="Transaction type" disabled="" value="Download")
                        v-col(cols="12" md="6")
                          v-text-field.purple-input(label="Total downloads" disabled="" :value="profile.downloads_business")
              // Plans
              v-tab-item
                v-form
                  v-container.py-0
                    v-row
                      v-col(cols="12" md="12")
                        h3 Plan
                      template(v-if="subscription")
                          v-col(cols="4")
                            v-subheader.pa-0.mt-3 Actual Plan
                          v-col(cols="8")
                            v-text-field.purple-input(disabled="" v-model="subscription.plan.name")
                          v-col(cols="4")
                            v-subheader.pa-0.mt-3 Cost
                          v-col(cols="8")
                            v-text-field.purple-input(disabled="" v-model="subscription.plan.amount")
                          v-col(cols="4")
                            v-subheader.pa-0.mt-3 Interval
                          v-col(cols="8")
                            v-text-field.purple-input(disabled="" v-model="subscription.plan.interval")
                          v-col(cols="4")
                            v-subheader.pa-0.mt-3 Create
                          v-col(cols="8")
                            v-text-field.purple-input(disabled="" :value="subscription.created | moment")
                          v-col(cols="4")
                            v-subheader.pa-0.mt-3 Start Date
                          v-col(cols="8")
                            v-text-field.purple-input(disabled="" :value="subscription.start | moment")
                          v-col(cols="4")
                            v-subheader.pa-0.mt-3 End Date
                          v-col(cols="8")
                            v-text-field.purple-input(disabled="" :value="subscription.end | moment")
                      template(v-if="!subscription")
                        v-row
                          v-col(cols="12")
                            v-card-title.justify-center.font-weight-light
                              | No active plans.
                            v-card-text.body-1.text-center.font-weight-light.grey--text
                              | Active plans that you have purchased will show up here.
                          v-col(cols="12").text-center
                            v-btn.mr-0(color="success" @click='goPurchase')
                              | Purchase
              // Payments
              v-tab-item
                v-form
                  v-container.py-0
                      v-row(v-if="payments")
                        v-col(cols="4")
                        v-col(cols="8")
                          v-text-field(v-model="search" append-icon="mdi-magnify" label="Search" single-line="" hide-details="")
                        v-col(cols="12")
                          v-data-table(:headers="headers" :search="search" :items="payments" :items-per-page="5")
                      v-row(v-else)
                        v-col(cols="12" md="12")
                          v-card-title.justify-center.font-weight-light
                            | No previous purchase history.
                          v-card-text.body-1.text-center.font-weight-light.grey--text
                            | Purchases that you make will show up here.
          v-card.custom-card-information.mt-0.user-profile__tab(v-else)
            v-tabs(left color="#3e7f9d" class="pt-5 pb-5 pr-4 pl-4")
              // Tabs
              v-tab
                v-icon(left="") mdi-account
                | User Profile
              v-tab
                v-icon(left="") mdi-credit-card-check-outline
                | Subscription
              v-tab
                v-icon(left="") mdi-currency-usd
                | Payments
              // Profile tab
              v-tab-item
                v-form
                  v-container.py-0
                      v-row
                        v-col(cols="8")
                          v-card.pa-1
                            v-row
                              v-col(cols="4")
                                v-text-field(label="Name" disabled="" v-model="profile.first_name")
                              v-col(cols="4")
                                v-text-field(label="Last Name" disabled="" v-model="profile.last_name")
                              v-col(cols="4")
                                v-text-field(label="Email" disabled="" v-model="profile.email")
                              v-col(cols="6")
                                v-text-field.purple-input(label="City" disabled="" v-model="profile.city")
                              v-col(cols="6")
                                v-text-field.purple-input(label="State" disabled="" v-model="profile.state")
                              v-col(cols="6")
                                v-text-field.purple-input(label="phone" disabled="" v-model="profile.phone")
                              v-col(cols="12").text-center
                                v-btn(color="secondary" rounded="" small @click.stop='updateItemDialog=true')
                                  | Edit profile
                        v-col(cols="4").mt-6
                          base-material-card(:avatar="profile.image ? profile.image:userImage")
                            v-card-text.text-center
                              p.grey--text
                                | {{profile.email}}
                              h3.mt-2.grey--text
                                | {{profile.first_name}}
                              p.mt-2.grey--text
                                | {{profile.phone}}
                              h3.mt-2.grey--text(v-if="subscription")
                                | {{subscription.plan.name}}
                              h3.mt-2.grey--text(v-if="subscription")
                                | {{subscription.status}}
                              span(v-if="subscription && tokens")
                                h3.mt-2.mb-1.grey--text Remaining downloads
                                p.grey--text Image {{tokens.image}}
                                p.grey--text Video {{tokens.video}}
              // Bank Information
              v-tab-item
                v-container
                  v-row(v-if="subscription")
                    v-col(cols="4")
                      v-card(flat v-if="subscription.plan" class="custom-card-transactions")
                        .custom-card__title()
                          h2.custom-card-transactions__balance
                            | {{subscription.plan.amount | moneyFormat}}
                        .custom-card__subtitle(v-if="subscription.plan.metadata")
                          span(v-if="subscription.plan.metadata.image") {{subscription.plan.metadata.image}} Images -
                          span(v-if="subscription.plan.metadata.video") {{subscription.plan.metadata.video}} Videos
                      //- update plans
                      v-card(v-if="plans && subscription.plan.metadata" flat)
                        v-row(v-if="subscription.plan.metadata.image === '10'").text-center
                          v-col(cols="12")
                            v-btn(small rounded color="primary" @click="updatePlan('Advance')") Upgrade to Advance
                        v-row(v-if="subscription.plan.metadata.image === '20'").text-center
                          v-col(cols="12")
                            v-btn(small rounded color="primary" @click="updatePlan('Basic')") Downgrade to Basic
                          v-col(cols="12")
                            v-btn(small rounded color="primary" @click="updatePlan('Premium')") Upgrade to Premium
                        v-row(v-if="subscription.plan.metadata.image === '30'").text-center
                          v-col(cols="12")
                            v-btn(small rounded color="primary" @click="updatePlan('Advance')") Downgrade to Advance
                      //- update plan loop
                      //- v-card(v-if="plans && subscription" flat)
                        p {{subscription}}
                        span(v-for="(plan, i) in plans")
                          p {{i}} {{plan.nickname}}
                          v-btn(v-if="plan.id !== subscription.plan.id" small rounded color v-text="plan.metadata.image > subscription.plan.metadata.image ? 'Update':'Downgrade' ")
                    v-col(cols="8")
                      v-row
                        v-col(cols="6")
                          h3.text-center
                            | SUBSCRIPTION
                          //-fomr
                          v-text-field.purple-input(label="Name" disabled="" v-model="subscription.plan.name")
                          v-text-field.purple-input(label="Interval" disabled="" v-model="subscription.plan.interval")
                          v-text-field.purple-input(label="Created" disabled="" :value="subscription.created | moment")
                          v-text-field.purple-input(label="Date start" disabled="" :value="subscription.start | moment")
                          v-text-field.purple-input(label="Date end" disabled="" :value="subscription.end | moment")
                        v-col(cols="6")
                          h3.text-center
                            | CARD DATA
                          v-row
                            v-col(cols="6")
                              v-text-field.purple-input(label="Card number" disabled="" v-model="mainCard.number")
                            v-col(cols="6")
                              v-text-field.purple-input(label="Brand" disabled="" v-model="mainCard.brand")
                            v-col(cols="6")
                              v-text-field.purple-input(label="Exp Month" disabled="" v-model="mainCard.exp_month")
                            v-col(cols="6")
                              v-text-field.purple-input(label="Exp Year" disabled="" v-model="mainCard.exp_year")
                  template(v-else)
                    v-row
                      v-col(cols="12")
                        v-card-title.justify-center.font-weight-light
                          | No active plans.
                        v-card-text.body-1.text-center.font-weight-light.grey--text
                          | Active plans that you have purchased will show up here.
                      v-col(cols="12").text-center
                        v-btn.mr-0(color="success" @click='goPurchase')
                          | Purchase
              // Payments
              v-tab-item
                v-form
                  v-container.py-0
                      v-row(v-if="payments")
                        v-col(cols="4")
                        v-col(cols="8")
                          v-text-field(v-model="search" append-icon="mdi-magnify" label="Search" single-line="" hide-details="")
                        v-col(cols="12")
                          v-data-table(:headers="headers" :search="search" :items="payments" :items-per-page="5")
                      v-row(v-else)
                        v-col(cols="12" md="12")
                          v-card-title.justify-center.font-weight-light
                            | No previous purchase history.
                          v-card-text.body-1.text-center.font-weight-light.grey--text
                            | Purchases that you make will show up here.
</template>

<script>
  import loaders from '@/plugins/mixins/loaders'
  import moment from 'moment'
  import userImage from '@/assets/user.png'
  const formatter = new Intl.NumberFormat('es-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    minimumIntegerDigits: 1,
  })
  export default {

    components: {
      Avatar: () => import('@/components/custom/Avatar'),
      UpdateItem: () => import('./Update/UpdateProfile'),
    },

    filters: {
      moment: function (date) {
        return moment(date * 1000).format('MM-DD-YYYY')
      },
      moneyFormat: function (value) {
        const entero = value.toString().slice(0, -2)
        const decimal = value.toString().slice(-2)
        return formatter.format(entero.concat('.', decimal))
      },
    },

    mixins: [loaders],
    data () {
      return {
        model: {},
        updateItemDialog: false,
        search: '',
        headers: [
          { text: 'Brand', value: 'brand' },
          { text: 'Numbers', value: 'number' },
          { text: 'Amount', value: 'amount' },
          { text: 'Plan', value: 'plan' },
        ],
        userImage: userImage,
      }
    },
    computed: {
      profileDialog () {
        return !!this.updateItemDialog
      },
      profile () {
        return this.$store.state.users.profile
      },
      isLoading () {
        return this.$store.getters['users/getLoading']
      },
      isMobile () {
        return this.$store.getters['users/getIsMobile']
      },
      payments () {
        if (this.$store.getters['subscriptions/getPayments']) {
          return Object.values(this.$store.getters['subscriptions/getPayments'])
        } else {
          return null
        }
      },
      customer () {
        return this.$store.getters['subscriptions/getStripe']
      },
      subscription () {
        if (this.$store.getters['subscriptions/getStripe']) {
          return this.$store.getters['subscriptions/getStripe'].subscription || null
        } else {
          return null
        }
      },
      tokens () {
        return this.$store.getters['downloads/getTokens']
      },
      mainCard () {
        return this.$store.getters['subscriptions/getMainCard']
      },
      plans () {
        return this.$store.getters['subscriptions/getPlans']
      },
    },

    created () {
      const vm = this
      vm.loadPayments()
      vm.loadProfile()
      vm.loadPlans()
    },

    methods: {
      updateDialog (val) {
        this.updateItemDialog = val
        this.$forceUpdate()
      },
      goSubscriptions () {
        const vm = this
        vm.$router.replace('/subscriptions')
      },
      goPurchase () {
        const vm = this
        vm.$router.replace('/Plans')
      },
      updatePlan (plan) {
        const vm = this
        const changePlan = vm.plans.find(element => element.nickname === plan)
        vm.$store.dispatch('subscriptions/setPlan', changePlan).then(function () {
          vm.$router.replace('/subscriptions')
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .custom-card{
    &__subtitle{
      font-size: 1rem;
      color: #fff;
      margin-top: 10px;
    }
  }
.custom-card-profile{
  margin-top: 50px;
  border-radius: 17px !important;
  position: relative;
  .btn-rol{
    font-size: 12pt;
    padding-top: .5rem;
    padding-bottom: .5rem;
    height: 50px;
  }
  &__settings{
    cursor: pointer;
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 25px;
    height: auto;
  }
  &__avatar{
    width: 150px;
    height: 150px;
    background-color: #eee;
    border-radius: 50% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto -50px;
    transform: translateY(-50%);
    .v-avatar{
      width: 90% !important;
      height: 90% !important;
    }
    img{
      border: 5px solid var(--font-color-2);
      object-fit: cover;
      // width: 60;
      //   width: 80%;
      //   height: auto;
      //   border-radius: 50%;
    }
  }
  &__username,
  &__usertype {
    color: var(--font-color-2);
  }
  &__usertype{
    font-weight: ligth;
  }
}
.custom-card-transactions{
  background-color: var(--font-color-2);
  border-radius: 17px !important;
  display: flex;
  flex-direction: column;
  &__title,
  &__balance{
    color: white;
    font-size: 20pt;
    font-weight: bold;
  }
  &__title{
    font-size: 18pt;
  }
  &__buttons{
  }
}
.custom-card-information {
  border-radius: 17px !important;
  border-bottom-left-radius: 17px !important;
  border-bottom-right-radius: 17px !important;
}
</style>
